<template>
	<div class="my-aq-list">
        <div class="aq-item">
            <div class="row">
                <span class="gender">女</span>
                <span class="age">32歲</span>
                <span class="create-time">7/1/2020</span>
            </div>
            <div class="title-w">
                <span class="category">#妇科</span>
                <h2 class="title">後腦經常出汗感到困擾 ？</h2>
            </div>
            <p class="content">後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴近這幾年, 都是少肉多菜, 所以不容情後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴近這幾年, 都是少肉多菜, 所以想問有沒有中醫調理方法可以有效治療。</p>
            <div class="answer-list">
                <div class="answer-item">
                    <div class="row">
                        <img src="../../../../assets/imgs/mobile/user_center/img_nvyis@2x.png" alt="" class="icon-doctor">
                        <span class="doctor-name">醫師趙亞平</span>
                        <span class="answer-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-content">我在大學教學生的時候，常常說，中醫必須要了解五行相生相克之道。口鹹，鹹屬水，腎亦屬水。故，口鹹，乃腎水不足。腎水不制(克制)心火，故半夜會扎醒，交泰丸，六味地黃丸可以一試。如未效，須重藥，需診中醫。</p>
                </div>
                <div class="answer-item">
                    <div class="row">
                        <img src="../../../../assets/imgs/mobile/user_center/img_nanyis@2x.png" alt="" class="icon-doctor">
                        <span class="doctor-name">醫師趙亞平</span>
                        <span class="answer-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-content">口鹹，鹹屬水，腎亦屬水。口鹹，乃腎水不足。</p>
                </div>
            </div>
            <div class="btn-expand">
                <img src="../../../../assets/imgs/mobile/user_center/icon_xiaoxi@2x.png" alt="" class="icon-answer">
                <span class="hint">3條回復</span>
            </div>
            <div class="btn-collapse">收起回復</div>
        </div>
        <div class="aq-item">
            <div class="row">
                <span class="gender">女</span>
                <span class="age">32歲</span>
                <span class="create-time">7/1/2020</span>
            </div>
            <div class="title-w">
                <span class="category">#妇科</span>
                <h2 class="title">後腦經常出汗感到困擾 ？</h2>
            </div>
            <p class="content">後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴近這幾年, 都是少肉多菜, 所以不容情後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴近這幾年, 都是少肉多菜, 所以想問有沒有中醫調理方法可以有效治療。</p>
            <div class="sub-question">
                <span class="hint">追问:</span>
                <span class="sub-question-content">为何涂抹甲硝锉软膏毫无用处甚至还严重了为何涂抹甲硝锉软膏毫无用处甚至还严重了为何涂抹甲硝锉软膏毫无用处甚至还严重了为何涂抹甲硝锉软膏毫无用处甚至还严重了</span>
            </div>
            <div class="answer-list">
                <div class="answer-item">
                    <div class="row">
                        <img src="../../../../assets/imgs/mobile/user_center/img_nvyis@2x.png" alt="" class="icon-doctor">
                        <span class="doctor-name">醫師趙亞平</span>
                        <span class="answer-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-content">我在大學教學生的時候，常常說，中醫必須要了解五行相生相克之道。口鹹，鹹屬水，腎亦屬水。故，口鹹，乃腎水不足。腎水不制(克制)心火，故半夜會扎醒，交泰丸，六味地黃丸可以一試。如未效，須重藥，需診中醫。</p>
                </div>
                <div class="answer-item">
                    <div class="row">
                        <img src="../../../../assets/imgs/mobile/user_center/img_nanyis@2x.png" alt="" class="icon-doctor">
                        <span class="doctor-name">醫師趙亞平</span>
                        <span class="answer-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-content">口鹹，鹹屬水，腎亦屬水。口鹹，乃腎水不足。</p>
                </div>
            </div>
            <div class="btn-expand">
                <img src="../../../../assets/imgs/mobile/user_center/icon_xiaoxi@2x.png" alt="" class="icon-answer">
                <span class="hint">3條回復</span>
            </div>
            <div class="btn-collapse">收起回復</div>
        </div>
    </div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>
	.my-aq-list {
		display: flex;
		flex-direction: column;
	}

	.my-aq-list .aq-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin: 16px 24px 0px 16px;
		padding: 31px 32px 40px 32px;
	}

	.my-aq-list .aq-item .row {
		display: flex;
		flex-direction: row;
	}

	.my-aq-list .aq-item .title-w {
		margin-top: 18px;
		margin-bottom: 21px;
	}

	.my-aq-list .aq-item .title-w .category {
		display: inline-block;
		color: #FF8000;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
	}

	.my-aq-list .aq-item .title-w .title {
		display: inline-block;
		color: #231F20;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
	}

	.my-aq-list .aq-item .gender,
	.my-aq-list .aq-item .age,
	.my-aq-list .aq-item .create-time {
		color: #969696;
		font-size: 26px;
		line-height: 25px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .gender {
		margin-right: 8px;
	}

	.my-aq-list .aq-item .age {
		margin-right: 28px;
	}

	.my-aq-list .aq-item .content {
		line-height: 39px;
		color: #696969;
		font-weight: 400;
		font-size: 28px;
	}

	.my-aq-list .aq-item .answer-list {
		background-color: #EFF4F9;
		margin: 30px -17px;
		padding-bottom: 30px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item {
		display: flex;
		flex-direction: column;
		padding: 30px 16px 0px 16px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .icon-doctor {
		width: 32px;
		height: 32px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .doctor-name {
		margin-left: 15px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-time {
		margin-left: 19px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-content {
		margin-left: 47px;
		color: #231F20;
		font-size: 28px;
		font-weight: 400;
		margin-top: 6px;
	}

	.my-aq-list .aq-item .btn-collapse {
		color: #36C4D0;
		font-weight: 400;
		font-size: 26px;
	}

	.my-aq-list .aq-item .btn-expand {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		display: none;
	}

	.my-aq-list .aq-item .btn-expand > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
		line-height: 25px;
		display: inline-block;
		height: 25px;
		padding-top: 2px;
	}

	.my-aq-list .aq-item .btn-expand > .icon-answer {
		width: 25px;
		height: 23px;
		margin-right: 23px;
	}

	.my-aq-list .aq-item .sub-question {
		font-size: 28px;
		color: #231F20;
		font-weight: bold;
		line-height: 32px;
		margin-top: 21px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.my-aq-list .aq-item .sub-question > .hint {
		color: #36C4D0;
	}
</style>
